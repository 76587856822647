import React from "react";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        
        <div className="addbg2">
          <div className="wrapper">
            <header>
              <h1 className="logo">
                <a href="/">Neutrogena</a>
              </h1>
              <nav>
                <a href="/produkty/" className="link">
                  Produkty
                </a>
                <span>| </span>
                <a href="/neutrogena/o-nas/" className="link">
                  NEUTROGENA<sup>®</sup>
                </a>
                <span>| </span>
                <a href="/budte-krasna/" className="link">
                  Buďte krásná
                </a>
                 
              </nav>
            </header>
            <div className="content">
              <div className="breadcrumb">
                <a href="/">
                  NEUTROGENA<sup>®</sup>
                </a>
                <span>&gt;</span>
                <a href="/produkty/">Popis</a>
                <span>&gt;</span>
                <a href="/product/94/#" className="blue">
                  {" "}
                  <p>
                    NEUTROGENA<sup>® </sup>Hydro&nbsp;Boost<sup>®</sup>{" "}
                    Hydratační pleťový gel - normální až smíšená pleť
                    <sup>
                      <br />
                    </sup>
                  </p>{" "}
                </a>
              </div>
              <div className="singleproduct">
                <div className="prodimgcont">
                  <div className="bot">
                    <img
                      src="/assets/Uploads/3574661533827_Neutrogena Hydro Boost Water_Gel_50ml_000.jpg"
                      alt="NEUTROGENA HydroBoost hydratacni pleovy gel 50ml normalni ple 500x"
                    />
                  </div>
                </div>
                <div className="info">
                  <h2>
                    {" "}
                    <p>
                      NEUTROGENA<sup>® </sup>Hydro&nbsp;Boost<sup>®</sup>{" "}
                      Hydratační pleťový gel - normální až smíšená pleť
                      <sup>
                        <br />
                      </sup>
                    </p>
                  </h2>
                  <dl>
                    <dt>Popis:</dt>
                    <dd>
                      <p>Zvyšte hydrataci své pleti pomocí NEUTROGENA® Hydro Boost® Hydratačního pleťového gelu, který je navržen tak, aby intenzivně hydratoval pokožku a pomohl posílit odolnost kožní bariéry. Osvěžující gelová textura zanechává pleť rovnoměrně hydratovanou, pružnou, svěží a rozzářenou po celý den.
                      </p>
                      <p>&nbsp;</p>
                      <p>
                        Trehalóza přírodního původu pomáhá uzamykat hydrataci v pokožce.
                      </p>
                      <p>&nbsp;</p>
                      <p>
                        Pleť je na pohled zdravá, hydratovaná a je odolnější vůči ztrátě vlhkosti.
                      </p>
                      <p className="updatepage">&nbsp;</p>
                      <p className="updatepage">Výsledky:</p>
                      <ul className="tablelist">
                      <li>Zlepšuje hydrataci pleti již po první aplikaci – klinicky potvrzeno.</li>
                      <li>Výrazné zlepšení hydratace pokožky během 24 hodin.</li>
                      </ul>
                      <p className="updatepage">&nbsp;</p>
                      <p className="updatepage">Po týdnu používání*:</p>
                      <ul className="tablelist">
                      <li>86 % žen vnímalo, že je jejich pokožka pružná </li>
                      <li>90 % žen vnímalo, že je jejich pleť hebká </li>
                      <li>77 % žen uvedlo, že přípravek poskytuje pleti dlouhodobou hydrataci</li>
                      </ul>
                      <p className="updatepage">&nbsp;</p>
                      <p className="updatepage">*Sebehodnocení, 84 žen užívajících přípravek jednou až dvakrát denně“</p>
                      <p className="updatepage">&nbsp;</p>
                      <p className="updatepage">Bez obsahu oleje</p>
                      <p className="updatepage">&nbsp;</p>
                      <p className="updatepage">Nekomedogenní</p>
                      <p className="updatepage">&nbsp;</p>
                      <p className="updatepage">Lehká gelová textura</p>
                      <p className="updatepage">&nbsp;</p>
                      <p className="updatepage">Testováno dermatology</p>
                    </dd>
                  </dl>
                  <dl>
                    <dt>Použití:</dt>
                    <dd>
                      Aplikujte ráno a večer na obličej a krk.
                    </dd>
                  </dl>
                  <dl className="last">
                    <dt>Balení:</dt>
                    <dd>50 ml</dd>
                  </dl>
                </div>
                <div id="fb_48"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="bluebar">
          <div className="bar">
            <div className="wrapper">
              <a href="/neutrogena/o-nas/">
                O ZNAČCE NEUTROGENA<sup>®</sup>
              </a>
              <span>|</span>
              <a href="/neutrogena/norska-receptura/">Norská receptura</a>
              <span>|</span>
              <a href="/neutrogena/historie/">Seznamte se s naší historií</a>
              <span>|</span>
              <a href="/produkty/">
                Zobrazit produkty NEUTROGENA<sup>®</sup>
              </a>
            </div>
          </div>
        </div>
        <Footer></Footer>
        <script
          dangerouslySetInnerHTML={{
            __html:
              "\n$(document).ready(function () {\n$('a').each(function () {\nvar linkhtml = $(this).html();\nif (linkhtml.indexOf('®') > -1 && linkhtml.indexOf('<sup>®</sup>') == -1) {\n$(this).html(linkhtml.replace(\"®\", \"<sup>®</sup>\"));\n}\n});\n});\n"
          }}
        />
        <script
          dangerouslySetInnerHTML={{
            __html:
              "\n$(document).ready(function () {\n$('a').each(function () {\nvar linkhtml = $(this).html();\nif (linkhtml.indexOf('®') > -1 && linkhtml.indexOf('<sup>®</sup>') == -1) {\n$(this).html(linkhtml.replace(\"®\", \"<sup>®</sup>\"));\n}\n});\n});\n"
          }}
        />
        
      </div>
    );
  }
}

export default Page;
